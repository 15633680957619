<template>
    <div>
        <vee-form ref="deletionForm" @submit="deleteUser">
            <v-card>
                <v-card-title class="text-subtitle-2">
                    {{ $t('UserDeletion.title') }}
                </v-card-title>
                <v-card-text>
                    <v-alert density="compact" class="mb-4" type="error" text>
                        {{ $t('UserDeletion.alertWarning') }}
                    </v-alert>

                    {{ $t('UserDeletion.infoText') }}

                    <vee-field
                        v-slot="{ componentField, errors }"
                        v-model="password"
                        :label="$t('OneWord.password')"
                        rules="required"
                        name="password"
                    >
                        <v-text-field
                            v-bind="componentField"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :error-messages="errors"
                            :label="$t('OneWord.password')"
                            :type="show ? 'text' : 'password'"
                            autocomplete="off"
                            counter
                            @click:append="show = !show"
                        />
                    </vee-field>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn :loading="deleting" type="submit">
                            {{ $t('UserDeletion.btnConfirmDelete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </vee-form>
    </div>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {EventBus} from '@/utils/event-bus';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'UserDeletion',
    components: {
        VeeForm,
        VeeField,
    },
    data: () => ({
        show: false,
        password: null,
        deleting: false,
    }),
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
    },
    mounted() {
        this.loadUser();
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
            setUser: 'set',
        }),
        ...mapActions('env', [
            'setEnv'
        ]),
        deleteUser() {
            this.deleting = true;
            this.$root.$confirm(
                this.$t('OneWord.delete'),
                this.$t('UserDeletion.confirmDialogText'),
            ).then(confirm => {
                if (confirm) {
                    axios.delete(`/webapi/users/${this.user.id}`, {
                        params: {
                            password: this.password,
                        }
                    }).then(() => {
                        axios.post('/webapi/logout').then(() => {
                            /**
                             * Reset application state
                             */
                            this.setEnv();
                            this.setUser();

                            /**
                             * Reset abilities to hide Navigation
                             */
                            this.$ability.update([]);

                            if (this.$route.name !== 'login') {
                                this.$router.push({name: 'login', query: {deleted: 1}});
                            }
                        });
                    }).catch(error => {
                        if (_.has(error, 'response.data.errors')) {
                            this.$refs['deletionForm'].setErrors(_.get(error, 'response.data.errors', []));
                        }
                        EventBus.$emit('error', _.get(error, 'response.data.message'));
                    }).finally(() => {
                        this.deleting = false;
                    });
                } else {
                    this.deleting = false;
                }
            }).catch(error => {
                EventBus.$emit('error', error.data.message);
            });
        },
    },
};
</script>