<template>
    <div>
        <h1 class="text-h6 mb-2">
            {{ $t('AdminAccount.title') }}
        </h1>

        <vee-form v-slot="{ handleSubmit }">
            <v-card :loading="loading" :disabled="loading">
                <v-card-text>
                    <account-form v-model="accountData" />
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="saving" @click="handleSubmit(saveAccount)">{{ $t('OneWord.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </vee-form>

        <h1 class="text-h6 mb-2 mt-6">
            {{ $t('AdminSubscription.title') }}
        </h1>

        <v-card v-if="hasSubscription" :loading="subscriptionLoading">
            <v-card-title>{{ subscription.name }}</v-card-title>
            <v-card-subtitle>
                <template v-if="subscription.status === 'future'">
                    {{ $t('AdminSubscription.subtitle.future', { price: price(subscription.plan.total), start_date: date(subscription.start_date) }) }}
                </template>
                <template v-else>
                    {{ $t('AdminSubscription.subtitle.live', { price: price(subscription.plan.total), current_term_starts_at: date(subscription.current_term_starts_at), current_term_ends_at: date(subscription.current_term_ends_at) }) }}
                </template>
            </v-card-subtitle>
            <v-card-text>
                <div>
                    {{ subscription.plan.description }}
                </div>

                <div v-if="subscription.addons.length > 0">
                    <div class="text-subtitle-1 mt-4">
                        <v-icon color="blue">mdi-lightbulb</v-icon>
                        Erweiterungen
                    </div>
                    <v-card
                        v-for="(addon, index) in subscription.addons"
                        :key="index"
                        class="mt-2"
                        border
                        color="blue-lighten-4"
                        variant="flat"
                    >
                        <v-card-title class="text-subtitle-2">{{ addon.name }}</v-card-title>
                        <v-card-subtitle>
                            {{ $t('AdminSubscription.subtitle.live', { price: price(addon.total), current_term_starts_at: date(subscription.current_term_starts_at), current_term_ends_at: date(subscription.current_term_ends_at) }) }}
                        </v-card-subtitle>
                        <v-card-text>{{ addon.description }}</v-card-text>
                    </v-card>
                </div>
            </v-card-text>
        </v-card>

        <v-card v-else>
            <v-card-title>{{ $t('AdminSubscription.noSubscriptionTitle') }}</v-card-title>
            <v-card-subtitle>{{ $t('AdminSubscription.noSubscriptionInfo') }}</v-card-subtitle>
        </v-card>

        <template v-if="hasSubscription">
            <h1 class="text-h6 mb-2 mt-6">
                {{ $t('AdminSubscription.invoices.title') }}
            </h1>

            <v-data-table
                :items="invoices"
                :headers="invoiceTableHeaders"
                :loading="invoicesLoading"
                class="elevation-1"
            >
                <template #item.number="{ item }">
                    <a :href="item.pdf_url" target="_blank" class="block cursor-pointer text-blue-600 underline">
                        {{ item.number }}
                    </a>
                </template>
                <template #item.status="{ item }">
                    <v-chip :color="getColor(item.status)" variant="flat">{{ statusText(item.status) }}</v-chip>
                </template>
                <template #item.invoice_date="{ item }">
                    {{ date(item.invoice_date) }}
                </template>
                <template #item.due_date="{ item }">
                    {{ date(item.due_date) }}
                </template>
                <template #item.total="{ item }">
                    {{ priceInvoice(item.total) }}
                </template>
            </v-data-table>
        </template>
    </div>
</template>

<script>
import AccountForm from './form/AccountForm';
import {Form as VeeForm} from "vee-validate";
import {EventBus} from '@/utils/event-bus';
import {mapActions, mapGetters} from 'vuex';
import store from '../../store';

export default {
    name: 'AdminSubscription',
    components: {AccountForm, VeeForm},
    props: {
        accountId: Number,
    },
    data() {
        return {
            loading: false,
            saving: false,
            accountData: {},
            subscriptionLoading: false,
            invoicesLoading: false,
            subscription: {
                plan: {},
                addons: [],
            },
            invoices: [],
            invoiceTableHeaders: [
                {title: this.$t('AdminSubscription.invoices.header.number'), value: 'number'},
                {title: this.$t('AdminSubscription.invoices.header.status'), value: 'status'},
                {title: this.$t('AdminSubscription.invoices.header.invoice_date'), value: 'invoice_date'},
                {title: this.$t('AdminSubscription.invoices.header.due_date'), value: 'due_date'},
                {title: this.$t('AdminSubscription.invoices.header.total'), value: 'total'},
            ],
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        ...mapGetters('account', {
            account: 'account',
        }),
        hasSubscription() {
            return !_.isEmpty(this.subscription);
        },
        currencySymbol() {
            return _.trim(_.get(this.subscription, 'currency_symbol', 'CHF'));
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),
        ...mapActions('account', {
            updateAccount: 'update',
        }),
        saveAccount() {
            this.saving = true;

            this.updateAccount(this.accountData).then(() => {
                EventBus.$emit('success', this.$t('AdminAccount.saveSuccess'));
            }).finally(() => {
                this.saving = false;
            });
        },
        load() {
            this.subscriptionLoading = true;
            this.invoicesLoading = true;

            this.loadUser().then(() => {
                store.dispatch('account/load', this.user.account_id).finally(() => {
                    this.accountData = _.cloneDeep(this.account);
                });

                axios.get(`/webapi/accounts/${this.user.account_id}/subscription`).then(response => {
                    this.subscription = response.data.data;
                }).catch(() => {
                    EventBus.$emit('error');
                }).finally(() => {
                    this.subscriptionLoading = false;
                });

                axios.get(`/webapi/accounts/${this.user.account_id}/invoices`).then(response => {
                    this.invoices = response.data.data;
                }).catch(() => {
                    EventBus.$emit('error');
                }).finally(() => {
                    this.invoicesLoading = false;
                });
            });
        },
        getColor(status) {
            switch (status) {
            case 'sent':
                return 'blue';
            case 'paid':
                return 'green';
            case 'overdue':
                return 'red';
            default:
                return 'grey';
            }
        },
        statusText(value) {
            switch (value) {
            case 'sent':
                return this.$t('AdminSubscription.invoices.status.sent');
            case 'paid':
                return this.$t('AdminSubscription.invoices.status.paid');
            case 'void':
                return this.$t('AdminSubscription.invoices.status.void');
            case 'overdue':
                return this.$t('AdminSubscription.invoices.status.overdue');
            default:
                return value;
            }
        },
        price(value) {
            return this.currencySymbol + ' ' + numeral(value).format('0');
        },
        date(value) {
            return moment(value).format('L');
        },
        priceInvoice(value) {
            return this.currencySymbol + ' ' + numeral(value).format('0,0.00');
        },
    },
};
</script>

<style scoped>

</style>
