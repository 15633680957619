<template>
    <vee-form ref="form" @submit="save()">
        <v-card>
            <v-card-text>
                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="user.email"
                    name="email"
                >
                    <v-text-field
                        v-bind="componentField"
                        :error-messages="errors"
                        :hint="$t('User.email.hint')"
                        :label="$t('OneWord.email')"
                        persistent-hint
                        type="email"
                        class="mb-4"
                        name="email"
                    />
                </vee-field>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="user.locale"
                    :name="$t('OneWord.locale')"
                    rules="required"
                >
                    <v-select
                        v-bind="componentField"
                        :error-messages="errors"
                        :items="languages"
                        :label="$t('OneWord.locale')"
                        item-title="label"
                        item-value="id"
                    />
                </vee-field>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="user.number_format"
                    :name="$t('OneWord.numberFormat')"
                    rules="required"
                >
                    <v-select
                        v-bind="componentField"
                        :error-messages="errors"
                        :items="numberFormats"
                        :label="$t('OneWord.numberFormat')"
                        item-title="label"
                        item-value="id"
                    />
                </vee-field>

                <v-alert type="info" class="mb-2">{{ $t('User.changePasswordInfo') }}</v-alert>

                <v-alert
                    type="error"
                    density="compact"
                    :model-value="passwordsDoNoMatch"
                    data-cy="passwords-not-match"
                    closable
                    class="mb-4"
                >
                    {{ $t('User.passwordsNotMatch') }}
                </v-alert>

                <v-alert
                    type="error"
                    density="compact"
                    :model-value="currentPasswordIncorrect"
                    data-cy="current-pw-incorrect"
                    closable
                    class="mb-4"
                >
                    {{ $t('OneWord.passwordCurrentHint') }}
                </v-alert>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="user.password"
                    :label="$t('OneWord.passwordNew')"
                    rules="min:8|max:255"
                    name="password"
                >
                    <v-text-field
                        v-bind="componentField"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="errors"
                        :hint="$t('User.password.hint')"
                        :label="$t('OneWord.passwordNew')"
                        :type="show ? 'text' : 'password'"
                        autocomplete="off"
                        data-cy="password-new"
                        loading
                        counter
                        @click:append="show = !show"
                    >
                        <template #loader>
                            <v-progress-linear
                                :model-value="pwdStrengthProgress"
                                :color="pwdStrengthColor"
                                height="7"
                            />
                        </template>
                    </v-text-field>
                </vee-field>

                <vee-field
                    v-slot="{ componentField, errors }"
                    v-model="confirm"
                    :label="$t('OneWord.passwordNewConfirmation')"
                    rules="min:8|max:255"
                    name="confirm"
                >
                    <v-text-field
                        v-bind="componentField"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        :label="$t('OneWord.passwordNewConfirmation')"
                        :hint="$t('User.password.hint')"
                        data-cy="password-confirm"
                        counter
                        :error-messages="errors"
                        autocomplete="off"
                        @click:append="show = !show"
                    />
                </vee-field>

                <vee-field
                    v-if="user.is_registered && user.password"
                    v-slot="{ componentField, errors }"
                    v-model="user.current_password"
                    :label="$t('OneWord.passwordCurrent')"
                    rules="required_if:password"
                    name="current_password"
                >
                    <v-text-field
                        v-bind="componentField"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        :label="$t('OneWord.passwordCurrent')"
                        :hint="$t('OneWord.passwordCurrentHint')"
                        data-cy="password-current"
                        counter
                        :error-messages="errors"
                        autocomplete="off"
                        @click:append="show = !show"
                    />
                </vee-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    data-cy="btn-save"
                    variant="flat"
                    :loading="loading"
                    @click="save()"
                >
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </vee-form>
</template>

<script>
import {Form as VeeForm, Field as VeeField} from "vee-validate";
import {mapActions, mapGetters} from 'vuex';
import zxcvbn from 'zxcvbn';
import {EventBus} from '@/utils/event-bus';

export default {
    name: 'UserAccount',
    components: {VeeForm, VeeField},
    props: {
        verified: Boolean,
    },
    data() {
        return {
            show: false,
            loading: false,
            confirm: '',
            passwordsDoNoMatch: false,
            currentPasswordIncorrect: false,
            user: {
                id: null,
                email: null,
                last_name: null,
                first_name: null,
                newsletter: null,
                password: '',
                current_password: '',
                locale: null,
                number_format: null,
            },
        };
    },
    computed: {
        ...mapGetters('user', {
            initUser: 'user',
        }),
        pwdStrengthProgress() {
            if (this.user.password) {
                let res = zxcvbn(this.user.password);
                return (res.score + 1) * 20;
            }

            return 0;
        },
        pwdStrengthColor() {
            if (this.pwdStrengthProgress > 80) {
                return 'success';
            }
            if (this.pwdStrengthProgress > 40 && this.pwdStrengthProgress <= 80) {
                return 'warning';
            }
            if (this.pwdStrengthProgress < 40) {
                return 'error';
            }

            return 'transparent';
        },
        languages() {
            return [
                {id: 'de-CH', label: this.$t('OneWord.lang.german')},
                {id: 'fr-CH', label: this.$t('OneWord.lang.french')},
                {id: 'it-CH', label: this.$t('OneWord.lang.italian')},
                {id: 'en-GB', label: this.$t('OneWord.lang.english')},
            ];
        },
        numberFormats() {
            let example = 1000;
            return [
                {id: 'de-CH', label: example.toLocaleString('de-CH')},
                {id: 'de-DE', label: example.toLocaleString('de-DE')},
                {id: 'en-GB', label: example.toLocaleString('en-GB')},
                {id: 'fr-CH', label: example.toLocaleString('fr-CH')},
            ];
        },
    },
    watch: {
        initUser: {
            deep: true,
            handler(value) {
                this.user = _.cloneDeep(value);
            },
        },
        verified(oldValue, newValue) {
            if (newValue === true && oldValue !== newValue) {
                this.displayVerified();
            }
        },
    },
    mounted() {
        this.user = _.cloneDeep(this.initUser);

        if (this.verified) {
            this.displayVerified();
        }
    },
    methods: {
        ...mapActions('user', {
            updateUser: 'update',
        }),
        save() {
            if (!_.isEmpty(this.user.password) && (this.user.password !== this.confirm)) {
                this.passwordsDoNoMatch = true;
                return;
            }
            else {
                this.passwordsDoNoMatch = false;
            }

            this.loading = true;

            this.updateUser(this.user).then(() => {
                EventBus.$emit('success', this.$t('User.updateSuccessful'));
                this.resetErrors();
            }).catch(error => {
                console.log('>>>');
                console.log(error);
                if (error.response.data.errors.current_password) {
                    this.currentPasswordIncorrect = true;
                } else {
                    EventBus.$emit('error', _.get(error, 'response.data.message'));
                    this.$refs.form.setErrors(_.get(error, 'response.data.errors'));
                }
            }).finally(() => {
                this.confirm = null;
                this.loading = false;
            });
        },
        displayVerified() {
            this.$nextTick(function() {
                EventBus.$emit('success', this.$t('User.emailVerifiedSuccess'));
            });
        },
        resetErrors() {
            this.passwordsDoNoMatch = false;
            this.currentPasswordIncorrect = false;
        }
    },
};
</script>

<style scoped lang="scss">
.Password {
    max-width: inherit !important;
}
</style>
