<template>
    <v-card class="mt-2">
        <v-card-text :class="{'pa-10': $vuetify.display.lgAndUp, 'pa-2': $vuetify.display.mdAndDown}">
            <v-row>
                <v-col class="hidden-md-and-down" lg="1">
                    <v-avatar color="accent" size="60">
                        <v-icon theme="dark" size="40">mdi-thumb-up</v-icon>
                    </v-avatar>
                </v-col>
                <v-col cols="12" lg="11">
                    <div class="text-h6 mb-4 mt-3">
                        {{ $t('TestamentCreated.title') }}
                    </div>
                </v-col>
            </v-row>

            <v-card class="bg-pen-and-paper mb-8 mt-4">
                <v-card-text class="pa-6">
                    <span class="text-h6">
                        {{ $t('TestamentCreated.card1.title') }}
                    </span>
                    <ol class="my-6 list-decimal" type="a">
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line1')" />
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line2')" />
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line3')" />
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line4')" />
                    </ol>

                    <a
                        :data-consultingcode="consultingCode"
                        :href="pdfUrl"
                        class="block rounded bg-da-dark px-8 py-4 font-semibold text-white hover:bg-da-pp lg:inline-block"
                        data-cy="testament-created-btn-pdf-download"
                        download=""
                    >
                        <v-icon color="white" start>mdi-file-outline</v-icon>
                        {{ $t('TestamentCreated.pdfDownload') }}
                    </a>

                    <button
                        v-if="$hasFeature($Features.Consulting)"
                        class="ml-4 block rounded px-8 py-4 font-semibold outline outline-1 hover:bg-da-pp hover:text-white lg:inline-block"
                        @click="$router.push({path: '/consulting'})"
                    >
                        {{ $t('OneWord.consulting') }}
                    </button>

                    <button
                        class="ml-4 block rounded px-8 py-4 font-semibold outline outline-1 hover:bg-da-pp hover:text-white lg:inline-block"
                        @click="newTestament"
                    >
                        {{ $t('TestamentCreated.newTestament') }}
                    </button>

                    <div v-if="showHomepageButton" class="mt-8 w-full text-left">
                        <a
                            :href="getHomepage"
                            class="block rounded px-8 py-4 font-semibold text-inherit outline outline-1 hover:bg-da-pp hover:text-white md:inline-block"
                        >{{ $t('TestamentCreated.btn.backToOrganization', {organizationName: getName}) }}</a>
                    </div>
                </v-card-text>
            </v-card>

            <more-documents :buttons="moreDocumentButtons" />
        </v-card-text>

        <v-dialog
            v-model="ngoThanksDialog"
            :fullscreen="$vuetify.display.mdAndDown"
            max-width="800"
            persistent
            @close="ngoThanksDialog=false"
        >
            <ngo-thanks-dialog
                tool="testament"
                :ngo-thanks="ngoThanks"
                :ngo-info="ngoInfo"
                :profiles="ngoThanksProfiles"
                @save="saveNgoThanks"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';
import MoreDocuments from '@/components/Upselling/MoreDocuments';
import NgoThanksDialog from '@/components/NgoThanksDialog';
import loadEnv from "@/plugins/env";
import { checkCompletion } from '@/composables/ToolStepper';

export default {
    name: 'TestamentCreated',
    
    components: {
        MoreDocuments, 
        NgoThanksDialog
    },

    data() {
        return {
            email: null,
            loading: false,
            emailError: false,
            profileSuccess: false,
            ngoThanksDialog: false,
            ngoThanks: false,
            ngoInfo: false,
            VUE_APP_DA_PUBLIC_URL: process.env.VUE_APP_DA_PUBLIC_URL,
        };
    },

    computed: {
        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),
        ...mapGetters('user', [
            'isAuthenticated',
        ]),
        ...mapGetters('testament', [
            'pdfUrl',
            'consultingCode',
            'testamentId',
            'testator',
            'ngos',
            'testamentPeople',
            'ngoThanksSubmitted',
        ]),
        ...mapGetters('env', [
            'isDa',
            'isGermany',
            'getName',
            'getHomepage'
        ]),

        moreDocumentButtons() {
            let buttons = [];

            if (this.$hasFeature(this.$Features.PatientDecree)) {
                buttons.push({
                    icon: 'mdi-file-star-outline',
                    label: this.$t('OneWord.patientDecree'),
                    to: {name: 'patient-decree-tool'},
                    id: 'testament-created-btn-patient-decree',
                });
            }

            if (this.$hasFeature(this.$Features.AdvanceCareDirective)) {
                buttons.push({
                    icon: 'mdi-file-account-outline',
                    label: this.$t('AppUserNav.advance_care_directive'),
                    to: {name: 'advance-care-directive-tool'},
                    id: 'testament-created-btn-advance-care-directive',
                });
            }

            if (this.$hasFeature(this.$Features.Consulting)) {
                buttons.push({
                    icon: 'mdi-phone-in-talk',
                    label: this.$t('OneWord.consulting'),
                    to: {name: 'consulting-tool'},
                    id: 'testament-created-btn-consulting',
                });
            }

            return buttons;
        },
        ngoThanksProfiles() {
            return this.ngos;
        },
        showHomepageButton() {
            return !(this.isDa || this.isGermany);
        },
    },

    mounted() {
        checkCompletion('testament');
        
        if ( ! this.ngoThanksSubmitted) {
            this.openNgoThanks();
        }
        window.scrollTo(0, 0);
    },

    methods: {
        ...mapActions('testament', {
            resetTestament: 'reset',
            setNgoThanksSubmitted: 'setNgoThanksSubmitted',
        }),
        openLink(location) {
            this.$router.push(location);
        },
        createProfile() {
            this.loading = true;
            this.emailError = false;
            axios.post('/webapi/users/add', {
                email: this.email,
                testament: this.testamentId,
                sponsored_by: this.hasAffiliateProfile ? this.affiliateProfile.id : null,
            }).then(() => {
                this.profileSuccess = true;
                loadEnv();
            }).catch(() => {
                this.emailError = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        openUrl(url) {
            window.open(url, '_blank');
        },
        openNgoThanks() {
            // Is there any NGO in the will, also with 0 values?
            let ngos = _.filter(this.testamentPeople, {type: 'ngo', is_additional: false});

            if (ngos.length > 0) {
                this.ngoThanksDialog = true;
            }

            // Is there a sponsor available?
            if (this.hasAffiliateProfile) {
                this.ngoThanksDialog = true;
            }
        },
        saveNgoThanks(settings) {
            if (settings) {
                this.ngoThanks = settings.ngoThanks;
                this.ngoInfo = settings.ngoInfo;

                axios.post('/webapi/testaments/ngo-settings', Object.assign(settings, {
                    testament: this.testamentId,
                })).then(() => {
                    this.setNgoThanksSubmitted();
                    EventBus.$emit('success', this.$t('TestamentCreated.ngoThanksConfirm'));
                }).finally(() => {
                    this.ngoThanksDialog = false;
                });
            } else {
                this.ngoThanksDialog = false;
            }
        },
        partnerTypes(profile) {
            return _(profile.partner_types).reduce((res, item) => {
                res.push(item.name);
                return res;
            }, []).join(', ');
        },
        hasListThumbnail(profile) {
            return _.has(profile, 'list_thumbnail') && !_.isEmpty(profile.list_thumbnail);
        },
        listThumbnail(profile) {
            return _.get(profile, 'list_thumbnail');
        },
        open(profile) {
            window.open(`${this.VUE_APP_DA_PUBLIC_URL}/dienstleisterverzeichnis/${profile.slug}`, '_blank');
        },
        newTestament() {
            this.resetTestament();
            this.$router.push({name: 'testament-tool'});
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
