<template>
    <v-card class="mt-2">
        <v-card-text :class="{'pa-10': $vuetify.display.lgAndUp, 'pa-2': $vuetify.display.mdAndDown}">
            <v-row class="mb-1">
                <v-col class="hidden-md-and-down" lg="1">
                    <v-avatar color="accent" size="60">
                        <v-icon theme="dark" size="40">mdi-thumb-up</v-icon>
                    </v-avatar>
                </v-col>
                <v-col cols="12" lg="11">
                    <div class="text-h6 mb-4 mt-3">
                        {{ $t('PatientDecreeCreated.title') }}
                    </div>
                </v-col>
            </v-row>

            <v-card class="bg-pen-and-paper mb-8">
                <v-card-text class="pa-6">
                    <span class="text-h6">
                        {{ $t('TestamentCreated.card1.title') }}
                    </span>
                    <ol class="my-6 list-decimal" type="a">
                        <li class="my-2" v-html="$t('PatientDecreeCreated.card1.line1')" />
                        <li class="my-2" v-html="$t('PatientDecreeCreated.card1.line2')" />
                        <li class="my-2" v-html="$t('PatientDecreeCreated.card1.line3')" />
                        <ul>
                            <li v-html="$t('PatientDecreeCreated.card1.line3a')" />
                            <li v-html="$t('PatientDecreeCreated.card1.line3b')" />
                        </ul>
                        <li class="my-2" v-html="$t('PatientDecreeCreated.card1.line4')" />
                    </ol>

                    <v-row class="my-4">
                        <v-col>
                            <a
                                :data-consultingcode="getConsultingCode"
                                :href="storedPatientDecree.url_pdf"
                                class="block rounded bg-da-dark px-8 py-4 font-semibold text-white hover:bg-da-pp lg:inline-block"
                            >
                                <v-icon color="white" start>mdi-file-outline</v-icon>
                                {{ $t('OneWord.pdfDownload') }}
                            </a>

                            <button
                                class="ml-4 block rounded px-8 py-4 font-semibold outline outline-1 hover:bg-da-pp hover:text-white lg:inline-block"
                                @click="newPatientDecree"
                            >
                                {{ $t('PatientDecreeCreated.newButton') }}
                            </button>
                        </v-col>
                    </v-row>

                    <v-card v-if="hasAuthorizedRepresentative" class="mt-2">
                        <v-card-text>
                            {{ $t('PatientDecreeCreated.sendInvitation.info') }}

                            <send-person-invitation
                                :label="$t('PatientDecreeCreated.authorizedRepresentative')"
                                :person="authorizedRepresentative"
                                :url="storedPatientDecree.url_invite"
                                type="authorized_representative"
                            />

                            <send-person-invitation
                                v-if="hasReplacementPerson"
                                :label="$t('PatientDecreeCreated.replacementPerson')"
                                :person="replacementPerson"
                                :url="storedPatientDecree.url_invite"
                                type="replacement_person"
                            />
                        </v-card-text>
                    </v-card>
                </v-card-text>
            </v-card>

            <more-documents :buttons="moreDocumentButtons" />
        </v-card-text>

        <v-dialog
            v-model="ngoThanksDialog"
            :fullscreen="$vuetify.display.mdAndDown"
            max-width="800"
            persistent
            @close="ngoThanksDialog=false"
        >
            <ngo-thanks-dialog
                tool="patientDecree"
                :ngo-thanks="ngoThanks"
                :ngo-info="ngoInfo"
                @save="saveNgoThanks"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import MoreDocuments from '@/components/Upselling/MoreDocuments';
import NgoThanksDialog from '@/components/NgoThanksDialog';
import SendPersonInvitation from './SendPersonInvitation';
import {mapActions, mapGetters} from 'vuex';
import { checkCompletion } from '@/composables/ToolStepper';

export default {
    name: 'PatientDecreeCreated',

    components: {
        MoreDocuments,
        NgoThanksDialog,
        SendPersonInvitation,
    },

    data() {
        return {
            authorizedRepresentativeSent: null,
            replacementPersonSent: null,
            ngoThanksDialog: false,
            ngoThanks: true,
            ngoInfo: false,
        };
    },

    computed: {
        ...mapGetters('patientDecree', {
            storedPatientDecree: 'patientDecree',
            authorizedRepresentative: 'authorizedRepresentative',
            replacementPerson: 'replacementPerson',
            hasAuthorizedRepresentative: 'hasAuthorizedRepresentative',
            hasReplacementPerson: 'hasReplacementPerson',
            getConsultingCode: 'getConsultingCode',
            ngoThanksSubmitted: 'ngoThanksSubmitted',
        }),

        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),

        moreDocumentButtons() {

            let buttons = [];

            if (this.$hasFeature(this.$Features.Testament)) {
                buttons.push({
                    icon: 'mdi-gavel',
                    label: this.$t('PatientDecreeCreated.card3.btn.testamentTool'),
                    to: {path: '/testament'},
                    id: 'patient-decree-btn-testament',
                });
            }

            if (this.$hasFeature(this.$Features.AdvanceCareDirective)) {
                buttons.push({
                    icon: 'mdi-file-account-outline',
                    label: this.$t('AppUserNav.advance_care_directive'),
                    to: {name: 'advance-care-directive-tool'},
                    id: 'testament-created-btn-advance-care-directive',
                });
            }

            if (this.$hasFeature(this.$Features.Consulting)) {
                buttons.push({
                    icon: 'mdi-phone-in-talk',
                    label: this.$t('OneWord.consulting'),
                    to: {name: 'consulting-tool'},
                    id: 'testament-created-btn-consulting',
                });
            }

            return buttons;
        },
    },

    mounted() {
        checkCompletion('patientDecree');

        if ( ! this.ngoThanksSubmitted) {
            this.openNgoThanks();
        }
    },
    
    methods: {
        ...mapActions('affiliateProfile', {
            loadAffiliate: 'load',
        }),

        ...mapActions('patientDecree', [
            'reset',
            'setNgoThanksSubmitted',
        ]),

        openLink(location) {
            this.$router.push(location);
        },

        openNgoThanks() {
            // only show the dialog if an affiliate is set and the tool has just been completed (not on a refresh)
            if (this.hasAffiliateProfile && this.storedPatientDecree.secure_id) {
                this.ngoThanksDialog = true;
            }
        },

        newPatientDecree() {
            this.reset();
            this.$router.push({name: 'patient-decree-tool'});
        },

        saveNgoThanks(settings) {
            if (settings) {
                this.ngoThanks = settings.ngoThanks;
                this.ngoInfo = settings.ngoInfo;

                axios.post('/webapi/patient-decree/ngo-settings', Object.assign(settings, {
                    patient_decree: this.storedPatientDecree.secure_id,
                })).then(() => {
                    this.setNgoThanksSubmitted();
                }).finally(() => {
                    this.ngoThanksDialog = false;
                });
            } else {
                this.ngoThanksDialog = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
button.text-wrap {
    width: 100%;

    :deep(.v-btn__content) {
        width: 100%;
        white-space: normal;
    }
}
</style>
