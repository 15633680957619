<template>
    <v-input
        model-value="date"
        :density="dense ? 'compact' : undefined"
        :error="dateError"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
    >
        <div class="w-100">
            <v-container class="pa-0">
                <v-row align="center" no-gutters>
                    <v-col cols="12">
                        <span class="text-caption">
                            {{ label }}
                        </span>
                    </v-col>
                </v-row>
                <v-row align="center" no-gutters>
                    <v-col cols="3">
                        <v-text-field
                            ref="dayInput"
                            :model-value="dayValue"
                            :density="dense ? 'compact' : undefined"
                            :label="$t('OneWord.day')"
                            data-cy="date-field-day"
                            type="number"
                            min="1"
                            max="31"
                            hide-details
                            variant="outlined"
                            :error="dayHasError"
                            @focus="$emit('focus')"
                            @update:model-value="handleDay"
                            @keydown.down.prevent="incrementDay($event.code)"
                            @keydown.up.prevent="incrementDay($event.code)"
                            @keydown.backspace.prevent="erase('dayValue')"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-select
                            ref="monthInput"
                            v-model="monthValue"
                            :items="months"
                            :label="$t('OneWord.month')"
                            :density="dense ? 'compact' : undefined"
                            data-cy="date-field-month"
                            hide-details
                            class="pa-4"
                            variant="outlined"
                            :error="monthHasError"
                            @focus="$emit('focus')"
                            @update:model-value="handleMonth"
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            ref="yearInput"
                            v-model.number="yearValue"
                            type="number"
                            min="1900"
                            :max="(new Date()).getFullYear().toString()"
                            :density="dense ? 'compact' : undefined"
                            :label="$t('OneWord.year')"
                            data-cy="date-field-year"
                            hide-details
                            variant="outlined"
                            :error="yearHasError"
                            @update:model-value="handleYear"
                            @focus="$emit('focus')"
                            @blur="dateError = computeDateError()"
                            @keydown.down.prevent="incrementYear($event.code)"
                            @keydown.up.prevent="incrementYear($event.code)"
                            @keydown.backspace.prevent="erase('yearValue')"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-input>
</template>

<script>
import {getCurrentLocale} from "@/plugins/i18n";
import EventBus from "@/utils/event-bus";

export default {
    name: 'DateField',
    components: {},
    props: {
        label: String,
        dense: Boolean,
        errorMessages: Array,
        hideDetails: Boolean,
        modelValue: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dayDataPurpose: null,
            dayError: false,
            dayValue: null,
            dateError: false,
            monthDataPurpose: null,
            monthError: false,
            monthValue: null,
            yearDataPurpose: null,
            yearError: false,
            yearValue: null,
            yearKey: 0,
            maxSupportedYear: 2100,
            minSupportedYear: 1900,
            minDayOrMonthValueForLoop: '01',
            minDayOrMonthValueForMarginBound: 0,
            minDayOrMonthValue: 1,
            maxDayValue: 31,
            maxMonthValue: 12,
            digitsInYear: 4,
            digitsInDayOrMonth: 2,
            maxDayOrMonthLength: 3,
            maxDayNumberWithPossibleSecondDigit: 3,
            maxMonthNumberWithPossibleSecondDigit: 1,
            highestThreeDigitNumber: 999,

            months: [],
        };
    },
    computed: {
        date() {
            return this.fullDate ? `${this.yearValue}-${this.monthValue}-${this.dayValue}` : null;
        },
        dayHasError() {
            return this.dayError || this.dateError || this.errorMessages?.length > 0;
        },
        monthHasError() {
            return this.monthError || this.dateError || this.errorMessages?.length > 0;
        },
        yearHasError() {
            return this.yearError || this.dateError || this.errorMessages?.length > 0;
        },
        fullDate() {
            return this.dayValue && this.monthValue && this.yearValue
        },
    },
    watch: {
        modelValue: {
            immediate: true,
            handler () {
                if (this.modelValue) {
                    const splitDate = this.modelValue.split('-');
                    this.dayValue = splitDate[2] ;
                    this.monthValue = splitDate[1];
                    this.yearValue = splitDate[0];
                }
            },
        },
        date: {
            immediate: true,
            handler () {
                if (this.date) {
                    this.$emit('update:modelValue', this.date);
                }
            },
        },
    },
    mounted() {
        EventBus.$on('locale-changed', () => {
            this.translateMonths();
        });

        this.translateMonths();
    },
    methods: {
        computeDateError () {
            try {
                const date = new Date(this.date)
                return date.getDate() !== parseInt(this.dayValue)
            } catch {
                return true
            }
        },
        incrementDay (key) {
            let value = this.dayValue;
            key === 'ArrowDown' ? value-- : value++;
            this.dayValue = this.formattedValue(this.loop(value, this.maxDayValue));
        },
        incrementMonth (key) {
            let value = this.monthValue;
            key === 'ArrowDown' ? value-- : value++;
            this.monthValue = this.formattedValue(this.loop(value, this.maxMonthValue));
        },
        incrementYear (key) {
            let value = this.yearValue;
            key === 'ArrowDown' ? value-- : value++;
            this.yearValue = this.formattedValue(this.loop(value, this.maxSupportedYear, this.minSupportedYear), this.digitsInYear);
        },
        handleDay (value) {
            /**
             * Cypress testing problem
             */
            if (window.Cypress) {
                this.dayValue = value;
                return;
            }

            this.dayError = false;
            if (value === '') return;

            value = this.marginBound(value, this.minDayOrMonthValueForMarginBound, this.maxDayValue);
            const passToNextField = parseInt(value) > this.maxDayNumberWithPossibleSecondDigit || value.length === this.maxDayOrMonthLength;

            this.dayValue = this.formattedValue(value);
            setTimeout(() =>  this.dayError = parseInt(this.dayValue) < this.minDayOrMonthValue || parseInt(this.dayValue) > this.maxDayValue, 500);
            if (passToNextField) this.$refs.monthInput.focus();
        },
        handleMonth (value) {
            this.monthError = false;
            if (value === '') return;

            value = this.marginBound(value, this.minDayOrMonthValueForMarginBound, this.maxMonthValue);
            const passToNextField = parseInt(value) > this.maxMonthNumberWithPossibleSecondDigit || value.length === this.maxDayOrMonthLength;

            this.monthValue = this.formattedValue(value);
            setTimeout(() =>  this.monthError = parseInt(this.monthError) < this.minDayOrMonthValue || parseInt(this.monthError) > this.maxMonthValue, 500);
            if (passToNextField) this.$refs.yearInput.focus();
        },
        handleYear (value) {
            this.yearValue = value;
            
            /**
             * Cypress testing problem
             */
            if (window.Cypress) {
                return;
            }

            value = this.formattedValue(value, this.digitsInYear);

            this.yearValue = this.marginBound(value, '0000', '9999');
            this.yearError = this.yearValue > this.highestThreeDigitNumber && (parseInt(this.yearValue) > this.maxSupportedYear || parseInt(this.yearValue) < this.minSupportedYear);
        },
        erase (field) {
            this[field] = '';
        },
        loop (value, max, min = this.minDayOrMonthValueForLoop) {
            if (parseInt(value) > max) return min;
            if (parseInt(value) < min) return max;

            return value;
        },
        marginBound (value, lowerBound, upperBound) {
            if (parseInt(value).toString().length < upperBound.toString().length) return value;
            if (parseInt(value) > upperBound) return upperBound;
            if (parseInt(value) < lowerBound) return lowerBound;
            return value;
        },
        formattedValue (value, digits = this.digitsInDayOrMonth) {
            const stringValue = value.toString();
            const difference = stringValue.length - digits;

            if (difference > 0) return stringValue.slice(difference);

            return stringValue.padStart(digits, '0');
        },
        translateMonths() {
            // set current locale for moment
            moment.locale(getCurrentLocale());

            this.months = [];
            for (let month = 1; month <= 12; month++) {
                this.months.push({
                    value: this.formattedValue(month),
                    title: moment({ month: (month - 1) }).format('MMMM'),
                });
            }
        },
    },
};
</script>

<style>
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>