<template>
    <v-card class="mt-2">
        <v-card-text v-if="response" :class="{'pa-10': $vuetify.display.lgAndUp, 'pa-2': $vuetify.display.mdAndDown}">
            <v-row>
                <v-col class="hidden-md-and-down" lg="1">
                    <v-avatar color="accent" size="60">
                        <v-icon theme="dark" size="40">mdi-thumb-up</v-icon>
                    </v-avatar>
                </v-col>
                <v-col cols="12" lg="11">
                    <div class="text-h6 mb-4 mt-3">
                        {{ $t('AdvanceCareDirectiveCreated.title') }}
                    </div>
                </v-col>
            </v-row>

            <v-card class="bg-pen-and-paper mb-8 mt-4">
                <v-card-text class="pa-6">
                    <span class="text-h6">
                        {{ $t('TestamentCreated.card1.title') }}
                    </span>
                    <ol class="my-6 list-decimal" type="a">
                        <li class="my-2" v-html="$t('AdvanceCareDirectiveCreated.card1.line1')" />
                        <li class="my-2" v-html="$t('AdvanceCareDirectiveCreated.card1.line2')" />
                        <li class="my-2" v-html="$t('AdvanceCareDirectiveCreated.card1.line3')" />
                        <li class="my-2" v-html="$t('AdvanceCareDirectiveCreated.card1.line4')" />
                    </ol>
                    <a
                        :data-consultingcode="response.code"
                        :href="response.url"
                        class="block rounded bg-da-dark px-8 py-4 font-semibold text-white hover:bg-da-pp lg:inline-block"
                        data-cy="advance-care-directive-btn-download-pdf"
                        download=""
                    >
                        <v-icon color="white" start>mdi-file-outline</v-icon>
                        {{ $t('OneWord.pdfDownload') }}
                    </a>
                    <button
                        class="ml-4 block rounded px-8 py-4 font-semibold outline outline-1 hover:bg-da-pp hover:text-white lg:inline-block"
                        @click="newAdvanceCareDirective"
                    >
                        {{ $t('AdvanceCareDirectiveCreated.newButton') }}
                    </button>
                </v-card-text>
            </v-card>

            <more-documents :buttons="moreDocumentButtons" />
        </v-card-text>

        <v-dialog
            v-model="ngoThanksDialog"
            :fullscreen="$vuetify.display.mdAndDown"
            max-width="800"
            persistent
            @close="ngoThanksDialog=false"
        >
            <ngo-thanks-dialog
                tool="advanceCareDirective"
                :ngo-thanks="ngoThanks"
                :ngo-info="ngoInfo"
                @save="saveNgoThanks"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import MoreDocuments from '@/components/Upselling/MoreDocuments';
import NgoThanksDialog from '@/components/NgoThanksDialog';
import { checkCompletion } from '@/composables/ToolStepper';

export default {
    name: 'AdvanceCareDirectiveCreated',
    components: {
        MoreDocuments,
        NgoThanksDialog,
    },
    data() {
        return {
            ngoThanksDialog: false,
            ngoThanks: true,
            ngoInfo: false,
        };
    },
    computed: {
        ...mapGetters('env', [
            'isAuthenticated',
        ]),
        ...mapGetters('advanceCareDirective', {
            advanceCareDirective: 'getAdvanceCareDirective',
            response: 'getResponse',
            ngoThanksSubmitted: 'ngoThanksSubmitted',
        }),
        ...mapGetters('affiliateProfile', {
            affiliateProfile: 'profile',
            hasAffiliateProfile: 'hasProfile',
        }),
        moreDocumentButtons() {
            let buttons = [];

            if (this.$hasFeature(this.$Features.AdvanceCareDirective)) {
                buttons.push({
                    icon: 'mdi-gavel',
                    label: this.$t('PatientDecreeCreated.card3.btn.testamentTool'),
                    to: {path: '/testament'},
                    id: 'advance-care-directive-btn-testament',
                });
            }

            if (this.$hasFeature(this.$Features.PatientDecree)) {
                buttons.push({
                    icon: 'mdi-file-star-outline',
                    label: this.$t('OneWord.patientDecree'),
                    to: {name: 'patient-decree-tool'},
                    id: 'testament-created-btn-patient-decree',
                });
            }

            if (this.$hasFeature(this.$Features.Consulting)) {
                buttons.push({
                    icon: 'mdi-phone-in-talk',
                    label: this.$t('OneWord.consulting'),
                    to: {name: 'consulting-tool'},
                    id: 'testament-created-btn-consulting',
                });
            }

            return buttons;
        },
    },
    mounted() {
        checkCompletion('advanceCareDirective');

        if ( ! this.ngoThanksSubmitted) {
            this.openNgoThanks();
        }
    },
    methods: {
        ...mapActions('advanceCareDirective', [
            'reset',
            'setNgoThanksSubmitted',
        ]),
        downloadPdf() {
            window.location.href = this.response.url;
        },
        openLink(location) {
            this.$router.push(location);
        },
        openNgoThanks() {
            // only show the dialog if an affiliate is set and the tool has just been completed (not on a refresh)
            if (this.hasAffiliateProfile && this.response.advance_care_directive) {
                this.ngoThanksDialog = true;
            }
        },
        newAdvanceCareDirective() {
            this.reset();
            this.$router.push({name: 'advance-care-directive-tool'});
        },
        saveNgoThanks(settings) {
            if (settings) {
                this.ngoThanks = settings.ngoThanks;
                this.ngoInfo = settings.ngoInfo;

                axios.post('/webapi/advance-care-directive/ngo-settings', Object.assign(settings, {
                    advance_care_directive: this.response.advance_care_directive
                })).then(() => {
                    this.setNgoThanksSubmitted();
                }).finally(() => {
                    this.ngoThanksDialog = false;
                });
            } else {
                this.ngoThanksDialog = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
