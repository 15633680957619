import {get} from 'lodash';
import router from '@/router/index.js';
import loadEnv from "@/plugins/env";
import {EventBus} from '@/utils/event-bus';

const initialState = {
    loaded: null,
    authenticated: false,
    tenant: null,
    lang: 'de',
};

const state = _.cloneDeep(initialState);

const getters = {
    isLoaded: state => get(state, 'loaded', false),
    isAuthenticated: state => get(state, 'authenticated', false),
    isDa: state => get(state, 'tenant.slug') === 'deinadieu-ch',
    isGermany: state => get(state, 'tenant.country') === 'DE',
    isSwitzerland: state => get(state, 'tenant.country', 'CH') === 'CH',
    isAustria: state => get(state, 'tenant.country', 'CH') === 'AT',
    isWhiteLabel: state => !(get(state, 'tenant.slug') === 'deinadieu-ch' || get(state, 'tenant.slug') === 'deinadieu-de' || get(state, 'tenant.slug') === 'deinadieu-at'),
    getFeatures: state => get(state, 'tenant.features', []),
    getLang: state => get(state, 'lang'),
    getCountry: state => get(state, 'tenant.country', 'CH'),
    getCurrency: state => get(state, 'tenant.currency', 'CHF'),
    getLogo: state => get(state, 'tenant.logo'),
    getHomepage: state => get(state, 'tenant.homepage'),
    getLanguages: state => get(state, 'tenant.languages', []),
    getName: state => get(state, 'tenant.name'),
    getSlug: state => get(state, 'tenant.slug'),
    getFavicon: state => get(state, 'tenant.favicon', '/favicon.ico'),
    getMailFromAddress: state => get(state, 'tenant.mailFromAddress', 'info@deinadieu.ch'),
    getColors: state => get(state, 'tenant.colors', []),
    getContent: state => get(state, 'tenant.content', ''),
};

const actions = {
    setEnv({commit}, env) {
        commit('setEnv', env);
    },

    logout({commit}) {
        // exclude the following route from a forced redirect after logout
        let doNotRedirect = [
            'advance_care_directive_affiliate',
            'advance-care-directive-tool',
            'consulting',
            'consulting-tool',
            'create-profile-account',
            'login',
            'patientenverfuegung-affiliate',
            'patient-decree-tool',
            'set-password',
            'testament-affiliate',
            'testament-tool',
        ];

        axios.post('/webapi/logout').then(() => {
            /**
             * Reset application state
             */
            commit('resetAuth');
            commit('user/resetState', {}, { root: true })
            commit('account/resetState', {}, { root: true })
            commit('notifications/resetState', {}, { root: true })

            /**
             * Remove persistent vuex storage.
             */
            window.localStorage.removeItem('vuex');

            /*
             * Reset tools data
             */
            commit('advanceCareDirective/resetState', {}, { root: true })
            commit('consulting/resetState', {}, { root: true })
            commit('patientDecree/resetState', {}, { root: true })
            commit('testament/resetState', {}, { root: true })

            /**
             * Reset abilities to hide Navigation
             */
            $ability.update([]);
            
            /**
             * Reload environment, abilities and features
             */
            loadEnv();

            // redirect to login page (if applicable)
            if ( ! doNotRedirect.includes(router.currentRoute.value.name)) {
                router.push({name: 'login', query: {path: router.currentRoute.value.path}});
            }
        });
    }
};

const mutations = {
    setEnv(state, data) {
        const oldLang = state.lang;

        state.authenticated = get(data, 'authenticated', false);
        state.tenant = get(data, 'tenant');
        state.lang = get(data, 'lang', 'de');
        state.loaded = true;

        if (state.lang && oldLang !== state.lang) {
            EventBus.$emit('locale-changed', state.lang);
        }
    },
    resetAuth(state) {
        state.authenticated = false;
    },
    resetState(state) {
        Object.assign(state, _.cloneDeep(initialState));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};